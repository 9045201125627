// Features add/remove Inactive classes

function wp_toggle_features() {

    // Get the .gif images from the "data-alt".
    var getGif = function () {
        var gif = [];
        jQuery('.product-feature-image img').each(function () {
            var data = jQuery(this).data('alt');
            gif.push(data);
        });
        return gif;
    }

    var gif = getGif();

    // Preload all the gif images.
    var image = [];

    jQuery(gif).each(function (index) {
        image[index] = new Image();
        image[index].src = gif[index];
    });

    jQuery('.description-icon ul li a').click(function(e){
        e.preventDefault();

        jQuery('.description-icon ul li a').removeClass('active');
        jQuery(this).addClass('active');

        // Get selected
        var link_clicked = jQuery(this).clone().attr('data-expand');
        // Hide all descriptions and product image
        jQuery('.description-feature, .product-feature-image').removeClass('show');

        var static_image = jQuery('.product-feature-image[data-product-feature=' + link_clicked + '] img').data('static');
        // console.log(static_image); 
        var animation = jQuery('.product-feature-image[data-product-feature=' + link_clicked + '] img').data('alt');
        // console.log(animation);

        jQuery('.product-feature-image[data-product-feature=' + link_clicked + '] img').attr('src',animation);

        // Show relevant
        jQuery('.description-feature[data-feature='+link_clicked+'], .product-feature-image[data-product-feature='+link_clicked+']').addClass('show'); 
        
        if (link_clicked == 'feature-5') {
            setTimeout(function () {
                jQuery('.product-feature-image[data-product-feature=' + link_clicked + '] img').attr('src', static_image);
            }, 3500);
        } else if (link_clicked == 'feature-6') {
            setTimeout(function () {
                jQuery('.product-feature-image[data-product-feature=' + link_clicked + '] img').attr('src', static_image);
            }, 7000);
        } else {
            setTimeout(function () {
                jQuery('.product-feature-image[data-product-feature=' + link_clicked + '] img').attr('src', static_image);
            }, 6500);
        }
        
        
    });

}