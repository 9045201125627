/*
|==========================================================================
| WordPress + Foundation
|==========================================================================
| 
| These functions make sure WordPress and Foundation play nice together,
| and this is also where we declare functions defined in this folder.
|
|==========================================================================
|
*/

document.addEventListener( "DOMContentLoaded", function() {

  // Evolving function for WCAG guidelines
  v8_wcag();

  // Detect browser and device
  detectBrowserAndDevice();

  // Enable skrollr
  // var s = skrollr.init();

  // Gravity Forms Placeholder Clearing
  // gravity_forms_placeholders();

  // Inline SVG replacement
  // inline_svg_replacement();

  // Isotope Masonry
  // isotope_masonry();

  // TablePress Cleanup
  // tablepress();

  onElementHeightChange( document.body, () => {
		AOS.refresh();
	} );

	jQuery( window ).resize( () => {
		AOS.refresh();

		if ( window.innerWidth > 1024 ) {
			jQuery( '#off-canvas' ).foundation( 'close' );
		}
	} );

  // Event Target Testing
  jQuery(document).click(function(event) {

  });

  // Window scroll detection
  jQuery( window ).bind( 'scroll', ( event ) => {
		sticky_header();
	} );

	sticky_header();

  // Remove empty P tags created by WP inside of Accordion and Orbit
  jQuery('.accordion p:empty, .orbit p:empty').remove();

	 // Makes sure last grid item floats left
  jQuery('.archive-grid .columns').last().addClass( 'end' );

	// Adds Flex Video to YouTube and Vimeo Embeds
  jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
    if ( jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5 ) {
      jQuery(this).wrap("<div class='widescreen flex-video'/>");
    } else {
      jQuery(this).wrap("<div class='flex-video'/>");
    }
  });

  // Homepage open modal
  var pages_need_modal = [".page-template-template-homepage"];

  for (var i = 0; i < pages_need_modal.length; i++) {
    if (jQuery(pages_need_modal[i]).length > 0) {
      
      if(jQuery('#homepageModal').length > 0) {
        setTimeout(function(){
          jQuery('#homepageModal').foundation('open');
        },2500);
      }

    }
  }

  // Cookies Hide Homepage Window Modal 

  // Set Cookies

  jQuery('#homepageModal button.close-button').click(function(event) {
    console.log('click');
    SetCookieAndhomepageModal();

  });

  // Enable PLYR for use
  var pages_need_plyr = [".page-template-template-support, .page-template-template-landing-page"];

  for (var i = 0; i < pages_need_plyr.length; i++) {
    if (jQuery(pages_need_plyr[i]).length > 0) {
      const players = new Plyr('.js-player');
    }
  }

  // Alternative PLYR for use in landing page
  var pages_alternative_need_plyr = [".page-template-template-landing-page"];

  for (var i = 0; i < pages_alternative_need_plyr.length; i++) {
    if (jQuery(pages_alternative_need_plyr[i]).length > 0) {
      
      var player = new Plyr('#player', {
        controls: ['restart', 'play', 'progress']
      });
      setTimeout(function () {
        var isPlaying = player.currentTime > 0 && !player.paused && !player.ended &&
          player.readyState > 2;
    
        if (!isPlaying) {
          player.play();
        }
      }, 50);

    }
  }


  // FAQ page

  jQuery('.page-template-template-faq .accordion-title').click(function(event) {

    jQuery('.excerpt p').removeClass('hide');

    jQuery('.accordion-title').text('Expand');
    
    $accordion = jQuery(this);
    

    if(jQuery('.page-template-template-faq .accordion-title.hover')) {
      // console.log(true);
      $paragraph = $accordion.parent().find('.excerpt p').removeClass('hide');
      $accordion.text('Expand');
    } else {
      $paragraph = $accordion.parent().find('.excerpt p').addClass('hide');
      $accordion.text('Collapse');
    }

  });

  // Enable Masonary Library for Dealers
  var pages_need_masonary = [".page-template-template-dealer"];

  for (var i = 0; i < pages_need_masonary.length; i++) {
    if (jQuery(pages_need_masonary[i]).length > 0) {
      
      jQuery('.grid').masonry({
        // options...
        itemSelector: '.grid-item',
        percentPosition: true,
        columnWidth: '.grid-sizer'
      });

    }
  }

  // Move warranty
  var pages_woocommerce = [".single-product"];

  for (var i = 0; i < pages_woocommerce.length; i++) {
    if (jQuery(pages_woocommerce[i]).length > 0) {
      
      $warranty = jQuery('form .warranty_info').clone().html();

      jQuery('.description').append('<p>'+$warranty+'</p><hr>');

      jQuery('form .warranty_info').remove();

    }
  }

 
  var page_registration = [".page-template-template-registration"];

  for (var i = 0; i < page_registration.length; i++) {
    if (jQuery(page_registration[i]).length > 0) {
      
      setTimeout(function(){
      jQuery('select#wwlc_country').val('US');
      jQuery('select#wwlc_country').trigger('change');
      jQuery('#fs_affiliates_register_form select').val('US');
      jQuery('#fs_affiliates_register_form select').trigger('change');
      },500);

    }
  }

  // For landing page
  // Slick Slider
  jQuery('.tslider').slick({
    dots: true,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    adaptiveHeight: true,
  });

});

/*
|==========================================================================
| Use this function to fire events after the entire window has loaded,
| including images and CSS files.
|==========================================================================
*/

window.onload = function() {
  AOS.init({

  // Global settings:
  disable: 'mobile', // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  // startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
  // initClassName: 'aos-init', // class applied after initialization
  // animatedClassName: 'aos-animate', // class applied on animation
  // useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  // disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  // debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  // throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
  

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  // offset: 120, // offset (in px) from the original trigger point
  // delay: 0, // values from 0 to 3000, with step 50ms
  // duration: 400, // values from 0 to 3000, with step 50ms
  // easing: 'ease', // default easing for AOS animations
  // once: false, // whether animation should happen only once - while scrolling down
  // mirror: false, // whether elements should animate out while scrolling past them
  // anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation);
});

};

function onElementHeightChange( elm, callback ) {
	let lastHeight = elm.clientHeight;
	let newHeight;

	( function run() {
		newHeight = elm.clientHeight;
		if ( lastHeight !== newHeight ) callback();
		lastHeight = newHeight;

		if ( elm.onElementHeightChangeTimer ) {
	  clearTimeout( elm.onElementHeightChangeTimer );
		}

		elm.onElementHeightChangeTimer = setTimeout( run, 200 );
	}() );
}