/*
|==========================================================================
| Sticky Header
|==========================================================================
| 
| This function gives us the option to implement a sticky header, with
| custom options for class, height, and calculation.
|
|==========================================================================
|
*/

function check_size() {

    var scroll_pos = jQuery(window).scrollTop();

    if (scroll_pos >= 125) {

        jQuery('body header.header').addClass('mini');

    } else {

        jQuery('body header.header').removeClass('mini');

    }
}

function sticky_header() {

    check_size();

    jQuery(window).on('resize', function () {
        check_size();
    });
}