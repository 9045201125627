/*
|==========================================================================
| Items in Viewport
|==========================================================================
| 
| This function detects if an item is in the viewport and could perhaps
| be improved upon.
|
|==========================================================================
| @reference: https://medium.com/talk-like/detecting-if-an-element-is-in-the-viewport-jquery-a6a4405a3ea2
|
*/

if(jQuery('a.btn.btn-large.faq').length > 0) {

  // The checker
  const gambitIsInView = el => {
    const scroll = window.scrollY || window.pageYOffset
    const boundsTop = el.getBoundingClientRect().top + scroll
    
    const viewport = {
      top: scroll,
      bottom: scroll + window.innerHeight,
    }
    
      const bounds = {
      top: boundsTop,
      bottom: boundsTop + el.clientHeight,
    }
    
    return ( bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom ) 
      || ( bounds.top <= viewport.bottom && bounds.top >= viewport.top );
  }


  // Usage.
  document.addEventListener( 'DOMContentLoaded', () => {
    const footer = document.querySelector( '#footer' );
    
    const handler = () => raf( () => {
      if (gambitIsInView( footer )) {
        console.log('In the viewport! =)');
        jQuery('a.btn.btn-large.faq').addClass('noshow');
      } else {
        console.log('Not in the viewport! =(');
        jQuery('a.btn.btn-large.faq').removeClass('noshow');
      }
    } )
    
    handler()
    window.addEventListener( 'scroll', handler );
  } )

  const raf = 
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    function( callback ) {
        window.setTimeout( callback, 1000 / 60 );
    }

}